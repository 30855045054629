import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-subscription-detail',
  templateUrl: './subscription-detail.component.html'
})
export class SubscriptionDetailComponent implements OnInit, OnDestroy {
  subscription:any;
  subscriptionUsers:any[] = [];
  standbyUsers:any[] = [];
  newSubscriptionUser:any;
  alarmFilter:any = {};
  alarmIdOrCustom:string = 'id';

  timer:any;
  activeEscalations:any = 0;
  defaultDbSpTemplate: string;
  dbSpCode: string;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/notif/subscriptions/spDefaultParams`).subscribe(
      data => {
        this.defaultDbSpTemplate = data["sp"] + "(";
        var params = data["params"];
        var paramsStr = "";
        var nl = '\n' + ' '.repeat(this.defaultDbSpTemplate.length);
        var i = 0;
        if (params && params.length > 0) {
          for (const param of params) {
            if (i > 0 && i % 3 == 0) {
              this.defaultDbSpTemplate += nl;
            }
            this.defaultDbSpTemplate += (i > 0 ? ", " : "") + `${(paramsStr.length ? ", " : "")}\$\{${param}\}`;
            i++;
          }
        }
        this.defaultDbSpTemplate += ")";

        if (!this.subscription.id && !this.subscription.dbTemplate) {
          this.subscription.dbTemplate = this.defaultDbSpTemplate;
          this.createSpCode();
        }
      }

    );
    this.resetNewSubscriptionUser();
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/notif/subscriptions/${id}`).subscribe(
            data => {
              this.setSubscription(data);
            }
          );

          var reqParams:HttpParams = new HttpParams()
            .set("subscriptionId", '' + id)
          ;
          this.http.get(`${this.env.e.url}/notif/subscriptionUsers`, {params:reqParams}).subscribe(
            data => {
              this.setSubscriptionUsers(data);
            }
          );
        }
      });

    this.timer = setInterval(()=> {
      if (this.subscription.id && this.subscription.enabled && this.subscription.enableEscalation) {
        this.reloadActiveEscalations();
      } else {
        this.activeEscalations = 0;
      }
    }, 10000);
  }

  public ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  reloadActiveEscalations() {
    this.http.get(`${this.env.e.url}/notif/subscriptions/${this.subscription.id}/countActiveEscalations`).subscribe(
        data => {
          this.activeEscalations = data;
        }
    );
  }

  reloadStandby() {
    if (!this.subscription.enableEscalation || !this.subscription.taskProductId || !this.subscription.taskCarrierId) {
      return;
    }

    var params:HttpParams = new HttpParams()
      .set("taskCarrierId", this.subscription.taskCarrierId)
      .set("taskProductId", this.subscription.taskProductId)
    ;

    this.http.get(`${this.env.e.url}/task/omni/standby`, {params:params}).subscribe(
      data => {
        this.setStandbyUsers(data);
      }
    );
  }

  setStandbyUsers(users) {
    this.standbyUsers = users;
  }

  resetNewSubscriptionUser() {
    this.newSubscriptionUser = {
      companyId: this.authService.getCompanyId(),
      departmentId: '',
      userId: '',
      priority: 0
    };
  }

  removeSubscriptionUser(i) {
    this.subscriptionUsers.splice(i, 1);
  }

  addSubscriptionUser() {
    this.subscriptionUsers.push(this.newSubscriptionUser);
    this.resetNewSubscriptionUser();
  }

  createNew() {
    this.subscription = {
      enabled: true,
      enableEscalation: true,
      autoComplete: true,
      autoCompleteMinutes: 5,
      delayMinutes: 3,
      sendSms: false,
      sendEmail: false,
      sendTask: false,
      sendVoice: false,
      sendItsmRequest: false,
      smsTemplate: "MICRO ${severity}\n" +
        "${ne}\n" +
        "${moInst}\n" +
        "${specProb}\n" +
        "${evtTime}",
      emailSubjectTemplate: "MICRO: Alarm ${severity} - ${nwType}:${ne}:${moClass}:${moInst}",
      emailBodyTemplate: "Severity: ${severity}\n" +
        "Network Type: ${nwType}\n" +
        "Network Element: ${ne}\n" +
        "MO. Class: ${moClass}\n" +
        "MO. Inst: ${moInst}\n" +
        "Specific Problem: ${specProb}\n" +
        "Probable Cause: ${probCause}\n" +
        "Proposed Repair Actions: ${repActs}\n" +
        "Source: ${source}\n" +
        "Site ID: ${siteId}\n" +
        "Event Time: ${evtTime}\n" +
        "Event Type: ${evtType}\n" +
        "Additional Text: ${addText}\n\n" +
        "${alarmUrl}",
      taskNameTemplate: "MICRO: Alarm - ${nwType}:${ne}:${moClass}:${moInst}",
      taskDescriptionTemplate: "<pre>Severity: ${severity}\n" +
        "Network Type: ${nwType}\n" +
        "Network Element: ${ne}\n" +
        "MO. Class: ${moClass}\n" +
        "MO. Inst: ${moInst}\n" +
        "Specific Problem: ${specProb}\n" +
        "Probable Cause: ${probCause}\n" +
        "Proposed Repair Actions: ${repActs}\n" +
        "Source: ${source}\n" +
        "Site ID: ${siteId}\n" +
        "Event Time: ${evtTime}\n" +
        "Event Type: ${evtType}\n" +
        "Additional Text: ${addText}</pre>\n\n" +
        "${alarmUrl}",
      itsmSubjectTemplate: "MICRO: Alarm - ${nwType}:${ne}:${moClass}:${moInst}",
      itsmDescriptionTemplate: "<pre>Severity: ${severity}\n" +
        "Network Type: ${nwType}\n" +
        "Network Element: ${ne}\n" +
        "MO. Class: ${moClass}\n" +
        "MO. Inst: ${moInst}\n" +
        "Specific Problem: ${specProb}\n" +
        "Probable Cause: ${probCause}\n" +
        "Proposed Repair Actions: ${repActs}\n" +
        "Source: ${source}\n" +
        "Site ID: ${siteId}\n" +
        "Event Time: ${evtTime}\n" +
        "Event Type: ${evtType}\n" +
        "Additional Text: ${addText}</pre>\n\n" +
        "${alarmUrl}",
      emailSeverityThreshold: 'CLEARED',
      smsSeverityThreshold: 'CLEARED',
      voiceSeverityThreshold: 'CRITICAL',
      emailDelayMinutes: 0,
      smsDelayMinutes: 0,
      voiceDelayMinutes: 3,
      voiceRetries: 3,
      sqlServerId: undefined,
      sqlSpTimeout: 15_000,
      dbTemplate:undefined,
      contacts:[]
    };
    this.setSubscription(this.subscription);
  }

  save() {
    if (this.alarmIdOrCustom === 'id') {
      this.subscription.customAlarmFilter = null;
    } else {
      this.subscription.alarmFilterId = null;
    }

    if (this.subscription.sendDb && !this.subscription.dbTemplate && this.defaultDbSpTemplate) {
      this.subscription.dbTemplate = this.defaultDbSpTemplate;
    }

    if (!this.subscription.id) {
      this.http.post(`${this.env.e.url}/notif/subscriptions`, this.subscription)
        .subscribe(
          data => {
            this.setSubscription(data);
            this.saveContactsAndUsers();
            this.alertService.info(`Created ${this.subscription.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/notif/subscriptions/${this.subscription.id}`, this.subscription)
        .subscribe(
          data => {
            this.setSubscription(data);
            this.saveContactsAndUsers();
            this.alertService.info(`Updated subscription Settings`);
          }
        );
    }
  }

  saveContactsAndUsers() {
    var params:HttpParams = new HttpParams()
      .set("subscriptionId", this.subscription.id)
    ;

    this.http.post(`${this.env.e.url}/notif/subscriptionUsers/merge`, this.subscriptionUsers, {params:params})
      .subscribe(
        data => {
          this.setSubscriptionUsers(data);
          this.alertService.info(`Merged Users`);
        }
      );
  }

  delete() {
    this.http.delete(`${this.env.e.url}/notif/subscriptions/${this.subscription.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted subscription ${this.subscription.name}`);
          this.router.navigate(["/notif/subscriptions"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  setSubscription(subscription) {
    this.subscription = subscription;
    this.alarmIdOrCustom = subscription.alarmFilterId ? 'id' : 'custom';
    this.reloadFilter();
    this.reloadStandby();
    this.reloadActiveEscalations();
    this.createSpCode();
  }

  setSubscriptionUsers(users) {
    this.subscriptionUsers = users;
  }

  reloadFilter() {
    if (this.alarmIdOrCustom === 'id') {
      if (this.subscription.alarmFilterId) {
        this.http.get(`${this.env.e.url}/alarm/filters/${this.subscription.alarmFilterId}`).subscribe(
          data => {
            this.alarmFilter = data as any;
          }
        );
      } else {
        this.alarmFilter = {};
      }
    } else {
      this.alarmFilter = {
        value: this.subscription.customAlarmFilter
      }
    }
  }

  createSpCode() {
    var codeTemplate =
      "-- MYSQL --\n" +
      "DELIMITER //\n" +
      "CREATE PROCEDURE ${__spName__}(\n" +
      "${__mysqlSpParams__}" +
      ")\n" +
      "BEGIN\n" +
      "   -- Do processing of alarm here\n" +
      "END//\n" +
      "DELIMITER ;\n";

    codeTemplate +=
      "\n-- ORACLE --\n" +
      "CREATE PROCEDURE ${__spName__}(\n" +
      "${__oraSpParams__}" +
      ")\n" +
      "BEGIN\n" +
      "   -- Do processing of alarm here\n" +
      "END;\n";

    codeTemplate +=
      "\n-- MS SQLSERVER --\n" +
      "CREATE PROCEDURE ${__spName__}(\n" +
      "${__sqlsvrSpParams__}" +
      ") AS\n" +
      "BEGIN\n" +
      "   -- Do processing of alarm here\n" +
      "END\n" +
      "GO\n";

    var parsed = this.parseDbTemplate(this.subscription.dbTemplate);

    if (parsed) {
      var mysqlParamStr = "";
      var oraParamStr = "";
      var sqlsvrParamStr = "";
      for (const param of parsed.params) {
        mysqlParamStr += (mysqlParamStr.length ? "   , ": "   ") + `IN ${param} VARCHAR(4096)\n`;
        oraParamStr += (oraParamStr.length ? "   , ": "   ") + `${param} IN NVARCHAR(4096)\n`;
        sqlsvrParamStr += (sqlsvrParamStr.length ? "   , ": "   ") + `@${param} NVARCHAR(4096) = NULL\n`;
      }
      codeTemplate = codeTemplate.replace(/\$\{__spName__\}/gis, parsed.sp);
      codeTemplate = codeTemplate.replace(/\$\{__mysqlSpParams__\}/gis, mysqlParamStr);
      codeTemplate = codeTemplate.replace(/\$\{__oraSpParams__\}/gis, oraParamStr);
      codeTemplate = codeTemplate.replace(/\$\{__sqlsvrSpParams__\}/gis, sqlsvrParamStr);
    } else {
      codeTemplate = codeTemplate.replace(/\$\{__spName__\}/gis, "?");
      codeTemplate = codeTemplate.replace(/\$\{__mysqlSpParams__\}/gis, "   -- IN ? VARCHAR(4096)\n");
      codeTemplate = codeTemplate.replace(/\$\{__oraSpParams__\}/gis, "   -- ? IN NVARCHAR(4096)\n");
      codeTemplate = codeTemplate.replace(/\$\{__sqlsvrSpParams__\}/gis, "   -- @? NVARCHAR(4096)\n");
    }
    this.dbSpCode = codeTemplate;
  }

  parseDbTemplate(template): any {
    var mainRegex = /^\s*(\w+)\s*\((.*)\)\s*$/gis;
    var paramRegex = /\s*(?:\${)*(\w+)(?:\})*\s*,*\s*/gis;
    var mainMatch = mainRegex.exec(template);
    if (!mainMatch) return undefined;
    let sp = mainMatch[1];
    let paramStr = mainMatch[2];
    let paramMatch;
    let params: string[] = [];

    while ((paramMatch = paramRegex.exec(paramStr)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (paramMatch.index === paramRegex.lastIndex) {
        paramRegex.lastIndex++;
      }
      paramMatch.forEach((match, groupIndex) => {
        if (groupIndex == 1) {
          params.push(match.toString().replace("${", "").replace("}", ""));
        }
      });
    }
    return {
      "sp" : sp,
      "params": params
    };
  }

  sendDbToggle() {
    if (this.subscription.sendDb) {
      if (!this.subscription.dbTemplate) {
        this.subscription.dbTemplate = this.defaultDbSpTemplate;
      }
      this.createSpCode();
    }
  }
}
