<div *ngIf="subscription">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>notifications_active</mat-icon>
      {{!subscription.id ? 'Create new Notification' : ('Notification ' + subscription.name)}} {{subscription.enabled ? '' : '(Disabled)'}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" [disabled]="!form.form.valid || !alarmFilter || !alarmFilter.value || alarmFilter?.value?.trim() === ''" (click)="save()">
        <mat-icon>{{!subscription.id ? 'add' : 'save'}}</mat-icon>
        {{(subscription.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm style="margin-left: 5px;" *ngIf="subscription.id" (onConfirm)="delete()"></micro-delete-confirm>

      <div class="fill-space"></div>
      <div matTooltip="There are {{this.activeEscalations}} active escalations for this subscription" *ngIf="this.activeEscalations && this.activeEscalations !== 0" class="boxwrapper" class="CRITICAL boxwrapper jello-horizontal" style="width: 60px; display: inline-grid; align-content: center; text-align: center; font-size: 18px">
        <div>{{this.activeEscalations}}</div>
      </div>
    </div>
  </div>

  <form #form="ngForm" role="form">
    <mat-tab-group>
      <mat-tab label="Settings">
        <div class="hbox-nowrap">
          <div style="width: 100%;">
            <mat-toolbar id="section_subscription">Subscription Settings</mat-toolbar>
            <div class="vbox-space padded">
              <div class="hbox">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100"><mat-icon class="icon-small">subscriptions</mat-icon> Subscription Settings</th>
                  </tr>
                  <tr>
                    <th colspan="100">Settings</th>
                  </tr>
                  <tr>
                    <th [ngClass]="subscription.enabled ? '' : 'MAJOR'">Enabled</th>
                    <th>Name</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input type="checkbox" [(ngModel)]="subscription.enabled" id="enabled" name="enabled"></td>
                    <td><input [(ngModel)]="subscription.name" required id="name" name="name"></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <micro-objectGroup-picklist type="SUBSCRIPTION" [(selectedItems)]="subscription.groups"></micro-objectGroup-picklist>
              </div>
            </div>

            <mat-toolbar id="section_escalations"><mat-icon>arrow_upward</mat-icon> Escalation Settings</mat-toolbar>
            <div class="padded">
              <table class="basicTable">
                <thead>
                <tr>
                  <th colspan="100"><mat-icon class="icon-small">arrow_upward</mat-icon> Escalation Settings {{subscription.enableEscalation ? '' : ' (Disabled)'}}</th>
                </tr>
                <tr>
                  <th colspan="100">Settings</th>
                </tr>
                <tr>
                  <th [ngClass]="subscription.enableEscalation ? '' : 'WARNING'" matTooltip="It is recommended to setup escalations">Enable Escalation</th>
                  <th matTooltip="If you expect an alarm to clear within a short period, specify this value to buffer the escalation process">Initial Delay (Minutes)</th>
                  <th [ngClass]="subscription.autoComplete ? 'WARNING' : ''" matTooltip="Auto-Completion will clear the complete the escalation automatically after the alarm has cleared for the specified duration">Enable Auto-Completion</th>
                  <th matTooltip="The amount of minutes an alarm should stay before auto-completing it, recommended at least 5 minutes" [ngClass]="subscription.autoCompleteMinutes < 5 ? 'WARNING' : ''">Auto-Complete Duration (Minutes)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td matTooltip="Note that the delay rules will affect if and when notifications are sent">
                    <input type="checkbox" [(ngModel)]="subscription.enableEscalation" id="enableEscalations" name="enableEscalations">
                  </td>
                  <td><input type="number" [(ngModel)]="subscription.delayMinutes" required id="delayMinutes" name="delayMinutes" min="0" [disabled]="!subscription.enableEscalation"></td>
                  <td><input type="checkbox" [(ngModel)]="subscription.autoComplete" id="autoComplete" name="autoComplete" [disabled]="!subscription.enableEscalation"></td>
                  <td>
                    <input type="number" [(ngModel)]="subscription.autoCompleteMinutes" required id="autoCompleteMinutes" name="autoCompleteMinutes" min="0" [disabled]="!subscription.enableEscalation">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <mat-toolbar id="section_channels"><mat-icon>all_inclusive</mat-icon> Channel Settings</mat-toolbar>
            <div>
              <mat-toolbar><input type="checkbox" [(ngModel)]="subscription.sendTask" id="sendTask" name="sendTask"> <label for="sendTask" style="cursor: pointer;">Task</label></mat-toolbar>
              <div *ngIf="subscription.sendTask" class="padded">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">
                      <mat-icon class="icon-small">work</mat-icon> Task Settings {{subscription.sendTask ? '' : ' (Disabled)'}}
                    </th>
                  </tr>
                  <tr>
                    <th>Carrier</th>
                    <th>Product</th>
                    <th>Project</th>
                    <th>Phase</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><micro-taskCarrier-select [(taskCarrierId)]="subscription.taskCarrierId" [required]="true" [disabled]="!subscription.sendTask"></micro-taskCarrier-select></td>
                    <td><micro-taskProduct-select [taskCarrierId]="subscription.taskCarrierId" [(taskProductId)]="subscription.taskProductId" [required]="true" [disabled]="!subscription.sendTask" (taskProductIdChange)="reloadStandby()"></micro-taskProduct-select></td>
                    <td><micro-taskProject-select [taskCarrierId]="subscription.taskCarrierId" [taskProductId]="subscription.taskProductId" [(taskProjectId)]="subscription.taskProjectId" [required]="true" [disabled]="!subscription.sendTask"></micro-taskProject-select></td>
                    <td><micro-taskPhase-select [taskCarrierId]="subscription.taskCarrierId" [taskProjectId]="subscription.taskProjectId" [(taskPhaseId)]="subscription.taskPhaseId" [required]="true" [disabled]="!subscription.sendTask"></micro-taskPhase-select></td>
                  </tr>
                  <tr>
                    <th colspan="100">Name</th>
                  </tr>
                  <tr>
                    <td colspan="100"><input [(ngModel)]="subscription.taskNameTemplate" required="required" style="width: 100%" placeholder="Task Name Template" id="taskNameTemplate" name="taskNameTemplate"></td>
                  </tr>
                  <tr>
                    <th colspan="100">Description</th>
                  </tr>
                  <tr>
                    <td colspan="100"><textarea rows="10" [(ngModel)]="subscription.taskDescriptionTemplate" required placeholder="Task Description Template" id="taskDescriptionTemplate" name="taskDescriptionTemplate"></textarea></td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <mat-toolbar><input type="checkbox" [(ngModel)]="subscription.sendItsmRequest" id="sendItsmRequest" name="sendItsmRequest"> <label for="sendItsmRequest" style="cursor: pointer;">ITSM</label></mat-toolbar>
              <div *ngIf="subscription.sendItsmRequest" class="padded vbox-space">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">ITSM Request Template</th>
                  </tr>
                  <tr>
                    <th>Carrier</th>
                    <th>New Status</th>
                    <th>Clear Status</th>
                    <th>Type</th>
                    <th>Priority</th>
                    <th>Urgency</th>
                    <th>Mode</th>
                    <th>Group</th>
                    <th>Level</th>
                    <th>Service Category</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><micro-itsmCarrier-select [(itsmCarrierId)]="subscription.itsmCarrierId" [required]="true"></micro-itsmCarrier-select></td>
                    <td><micro-itsmObject-select itsmObjectType="STATUS" [(itsmObjectId)]="subscription.itsmNewStatusId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="STATUS" [(itsmObjectId)]="subscription.itsmClearStatusId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="REQUEST_TYPE" [(itsmObjectId)]="subscription.itsmRequestTypeId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="PRIORITY" [(itsmObjectId)]="subscription.itsmPriorityId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="URGENCY" [(itsmObjectId)]="subscription.itsmUrgencyId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="MODE" [(itsmObjectId)]="subscription.itsmModeId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="GROUP" [(itsmObjectId)]="subscription.itsmGroupId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="LEVEL" [(itsmObjectId)]="subscription.itsmLevelId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="SERVICE_CATEGORY" [(itsmObjectId)]="subscription.itsmServiceCategoryId" [required]="true"></micro-itsmObject-select></td>
                  </tr>
                  <tr>
                    <th>Alert Source</th>
                    <th>Alert Type</th>
                    <th>Category</th>
                    <th>Sub-Category</th>
                    <th>Item</th>
                    <th>Assets</th>
                    <th>Impact</th>
                    <th colspan="2">Impact Details</th>
                  </tr>
                  <tr>
                    <td><micro-itsmObject-select itsmObjectType="ALERT_SOURCE" [(itsmObjectId)]="subscription.itsmAlertSourceId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="ALERT_TYPE" [(itsmObjectId)]="subscription.itsmAlertTypeId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="CATEGORY" [(itsmObjectId)]="subscription.itsmCategoryId" [required]="true"></micro-itsmObject-select></td>
                    <td><micro-itsmObject-select itsmObjectType="SUBCATEGORY" [(itsmObjectId)]="subscription.itsmSubCategoryId" [parentId]="subscription.itsmCategoryId" [required]="true"></micro-itsmObject-select></td>
                    <td><input [(ngModel)]="subscription.itsmItem" id="item" name="item"></td>
                    <td>-</td>
                    <td><micro-itsmObject-select itsmObjectType="IMPACT" [(itsmObjectId)]="subscription.itsmImpactId" [required]="true"></micro-itsmObject-select></td>
                    <td colspan="2"><input [(ngModel)]="subscription.itsmImpactDetails" id="impactDetails" name="impactDetails"/></td>
                  </tr>
                  <tr>
                    <th colspan="100">Subject</th>
                  </tr>
                  <tr>
                    <td colspan="100">
                      <input [(ngModel)]="subscription.itsmSubjectTemplate" required id="subject" name="subject"/>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="100">Description</th>
                  </tr>
                  <tr>
                    <td colspan="100">
                      <textarea rows="10" [(ngModel)]="subscription.itsmDescriptionTemplate" required placeholder="ITSM Description Template" id="itsmDescriptionTemplate" name="itsmDescriptionTemplate"></textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">ITSM Asset Template</th>
                  </tr>
                  <tr>
                    <th>Product Type</th>
                    <th>Product</th>
                    <th>CI Name</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input [(ngModel)]="subscription.itsmAssetProductTypeTemplate" id="itsmAssetProductTypeTemplate" name="itsmAssetProductTypeTemplate"></td>
                    <td><input [(ngModel)]="subscription.itsmAssetProductTemplate" id="itsmAssetProductTemplate" name="itsmAssetProductTemplate"></td>
                    <td><input [(ngModel)]="subscription.itsmAssetNameTemplate" id="itsmAssetNameTemplate" name="itsmAssetNameTemplate"></td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <mat-toolbar><input type="checkbox" [(ngModel)]="subscription.sendDb" id="sendDb" name="sendDb" (change)="sendDbToggle()"> <label for="sendDb" style="cursor: pointer;">Database</label></mat-toolbar>
              <div *ngIf="subscription.sendDb" class="padded">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">Database Template</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr>
                    <th><micro-valid-label for="sqlServerId" [model]="sqlServerId" label="SQL Server"></micro-valid-label></th>
                    <td>
                      <micro-sql-select #sqlServerId="ngModel"
                                        [(ngModel)]="subscription.sqlServerId"
                                        id="sqlServerId"
                                        name="sqlServerId">
                      </micro-sql-select>
                    </td>
                  </tr>
                  <tr>
                    <th><micro-valid-label for="sqlSpTimeout" [model]="sqlSpTimeout" label="SQL Timeout"></micro-valid-label></th>
                    <td><input #sqlSpTimeout="ngModel" [(ngModel)]="subscription.sqlSpTimeout" style="width: 100%" placeholder="15000" id="sqlSpTimeout" name="sqlSpTimeout"></td>
                  </tr>
                  <tr>
                    <th colspan="2"><micro-valid-label for="dbTemplate" [model]="dbTemplate" label="Stored Procedure Template"></micro-valid-label></th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <textarea style="width: 400px;"
                                rows="10" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                                #dbTemplate="ngModel"
                                [(ngModel)]="subscription.dbTemplate"
                                [placeholder]="defaultDbSpTemplate"
                                id="dbTemplate"
                                name="dbTemplate"
                                (change)="createSpCode()">
                      </textarea>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2"><micro-valid-label for="dbSpCodeFld" [model]="dbSpCodeFld" label="Create Stored Procedure Templates"></micro-valid-label></th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <textarea style="width: 400px;"
                                  rows="10"
                                  #dbSpCodeFld="ngModel"
                                  [(ngModel)]="dbSpCode"
                                  [readOnly]="true"
                                  id="dbSpCodeFld"
                                  name="dbSpCodeFld">
                      </textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <mat-toolbar><input type="checkbox" [(ngModel)]="subscription.sendEmail" id="sendEmail" name="sendEmail"> <label for="sendEmail" style="cursor: pointer;">E-Mail</label></mat-toolbar>
              <div *ngIf="subscription.sendEmail" class="padded">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">E-Mail Template</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr>
                    <th>Outgoing Mailbox</th>
                  </tr>
                  <tr>
                    <td><micro-smtp-select [(smtpId)]="subscription.smtpMailboxId"></micro-smtp-select></td>
                  </tr>
                  <tr>
                    <th>Subject</th>
                  </tr>
                  <tr>
                    <td><input [(ngModel)]="subscription.emailSubjectTemplate" required="required" style="width: 100%" placeholder="E-Mail Subject Template" id="emailSubjectTemplate" name="emailSubjectTemplate"></td>
                  </tr>
                  <tr>
                    <th>Body</th>
                  </tr>
                  <tr>
                    <td><textarea style="width: 400px;" rows="10" [(ngModel)]="subscription.emailBodyTemplate" required placeholder="E-Mail Body Template" id="emailBodyTemplate" name="emailBodyTemplate"></textarea></td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <mat-toolbar><input type="checkbox" [(ngModel)]="subscription.sendSms" id="sendSms" name="sendSms"> <label for="sendSms" style="cursor: pointer;">SMS</label></mat-toolbar>
              <div *ngIf="subscription.sendSms" class="padded">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">
                      <mat-icon class="icon-small">sms</mat-icon> SMS Settings
                    </th>
                  </tr>
                  <tr>
                    <th>ANumber</th>
                    <th>(Or) SMS Type</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><micro-anumber-select [(anumberId)]="subscription.anumberId" [disabled]="!subscription.sendSms"></micro-anumber-select></td>
                    <td><micro-smsType-select *ngIf="!subscription.anumberId" [(smsTypeId)]="subscription.smsTypeId" [disabled]="!subscription.sendSms"></micro-smsType-select></td>
                  </tr>
                  <tr>
                    <th colspan="100">Message</th>
                  </tr>
                  <tr>
                    <td colspan="100">
                      <textarea style="width: 400px;" rows="10" [(ngModel)]="subscription.smsTemplate" required placeholder="SMS Template" id="smsTemplate" name="smsTemplate"></textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <mat-toolbar><input type="checkbox" [(ngModel)]="subscription.sendVoice" id="sendVoice" name="sendVoice"> <label for="sendVoice" style="cursor: pointer;">Voice</label></mat-toolbar>
              <div *ngIf="subscription.sendVoice" class="padded">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">
                      <mat-icon class="icon-small">record_voice_over</mat-icon> Voice Settings  {{subscription.sendVoice ? (subscription.enableEscalation ? '' : '(Without escalations enabled, voice calls are made once per notification)') : ' (Disabled)'}}
                    </th>
                  </tr>
                  <tr>
                    <th>Voice Carrier</th>
                    <th matTooltip="Voice calls only trigger when alarm severity exceeds this severity threshold">
                      Severity Threshold
                    </th>
                    <th matTooltip="Wait {{subscription.voiceDelayMinutes}} minutes before sending a voice call in case of alarm acknowledged already. Minimum delay is 1 minutes"
                        [ngClass]="{'CRITICAL':subscription.voiceDelayMinutes < 1}">
                      Voice Call Delay (Minutes)
                    </th>
                    <th matTooltip="Try calling {{subscription.voiceRetries}} times, before escalating to next user">
                      Voice Call Retries
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><micro-voiceCarrier-select [(voiceCarrierId)]="subscription.voiceCarrierId" [required]="true" [disabled]="!subscription.sendVoice"></micro-voiceCarrier-select></td>
                    <td><micro-severity-select [(severity)]="subscription.voiceSeverityThreshold" [disabled]="!subscription.sendVoice"></micro-severity-select></td>
                    <td>
                      <input *ngIf="subscription.enableEscalation && subscription.sendVoice" type="number" min="1" max="60" [(ngModel)]="subscription.voiceDelayMinutes" required id="voiceDelayMinutes" name="voiceDelayMinutes">
                      <div *ngIf="!subscription.enableEscalation || !subscription.sendVoice">-</div>
                    </td>
                    <td>
                      <input *ngIf="subscription.enableEscalation && subscription.sendVoice" type="number" min="0" [(ngModel)]="subscription.voiceRetries" required id="voiceRetries" name="voiceRetries">
                      <div *ngIf="!subscription.enableEscalation || !subscription.sendVoice">-</div>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="100">Message</th>
                  </tr>
                  <tr>
                    <td colspan="100">
                      <textarea rows="10" [(ngModel)]="subscription.voiceTemplate" required placeholder="Voice Template" id="voiceTemplate" name="voiceTemplate"></textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <mat-toolbar id="section_users"><mat-icon>person</mat-icon>Users</mat-toolbar>
            <div class="vbox-space padded">
              <table class="basicTable">
                <thead>
                <tr>
                  <th colspan="100">Users</th>
                </tr>
                <tr>
                  <th>Department</th>
                  <th>User</th>
                  <th>E-mail</th>
                  <th>MSISDN</th>
                  <th style="width: 20px;">Priority</th>
                  <th>Real Priority</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th colspan="100">Product Standby Users (First Priorities)</th>
                </tr>
                <tr *ngIf="!standbyUsers || standbyUsers.length == 0">
                  <td colspan="100" [ngClass]="subscription.sendTask ? 'CRITICAL' : 'INDETERMINATE'">{{subscription.sendTask ? 'No product standby users found' : 'Task integration disabled'}}</td>
                </tr>
                <tr *ngFor="let standbyUser of standbyUsers; let i = index">
                  <td><micro-department-lookup [departmentId]="standbyUser.user.departmentId"></micro-department-lookup></td>
                  <td><micro-user-lookup [userId]="standbyUser.user.id"></micro-user-lookup></td>
                  <td [ngClass]="!standbyUser.user.email ? 'CRITICAL' : ''" [matTooltip]="!standbyUser.user.email ? 'No E-Mail for standby user' : ''"><micro-userEmail-lookup [userId]="standbyUser.user.id"></micro-userEmail-lookup></td>
                  <td [ngClass]="!standbyUser.user.msisdn ? 'CRITICAL' : ''" [matTooltip]="!standbyUser.user.msisdn ? 'No MSISDN for standby user' : ''"><micro-userMsisdn-lookup [userId]="standbyUser.user.id"></micro-userMsisdn-lookup></td>
                  <td style="text-align: center;">{{standbyUser.priority}}</td>
                  <td style="font-weight: bold;text-align: center;">{{standbyUser.priority}}</td>
                  <td></td>
                </tr>
                <tr>
                  <th colspan="100">Additional Users</th>
                </tr>
                <tr *ngFor="let user of subscriptionUsers; let i = index">
                  <td><micro-department-lookup [departmentId]="user.departmentId"></micro-department-lookup></td>
                  <td><micro-user-lookup [userId]="user.userId"></micro-user-lookup></td>
                  <td><micro-userEmail-lookup [userId]="user.userId"></micro-userEmail-lookup></td>
                  <td><micro-userMsisdn-lookup [userId]="user.userId"></micro-userMsisdn-lookup></td>
                  <td><input [(ngModel)]="user.priority" required id="subscriptionUserPriority{{i}}" name="subscriptionUserPriority{{i}}"></td>
                  <td style="font-weight: bold;text-align: center;">{{standbyUsers ? standbyUsers.length + user.priority : user.priority}}</td>
                  <td><button type="button" (click)="removeSubscriptionUser(i)">Remove</button></td>
                </tr>
                <tr>
                  <td><micro-department-select [companyId]="authService.getCompanyId()" [(departmentId)]="newSubscriptionUser.departmentId" [required]="true"></micro-department-select></td>
                  <td><micro-user-select [companyId]="authService.getCompanyId()" [departmentId]="newSubscriptionUser.departmentId" [(userId)]="newSubscriptionUser.userId" [required]="true"></micro-user-select></td>
                  <td></td>
                  <td></td>
                  <td><input [(ngModel)]="newSubscriptionUser.priority" required id="newSubscriptionUserPriority" name="newSubscriptionUserPriority"></td>
                  <td></td>
                  <td><button type="button" (click)="addSubscriptionUser()">Add</button></td>
                </tr>
                </tbody>
              </table>
            </div>

            <mat-toolbar id="section_contacts"><mat-icon>phone</mat-icon> External Contacts</mat-toolbar>
            <div class="padded">
              <div class="boxwrapper">
                <micro-contact-tree-select [(selectedContactIds)]="subscription.contacts" [applyStyling]="false"></micro-contact-tree-select>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Alarm Filter">
        <div class="hbox lightly-padded button-bar">
          <micro-radio [options]="[{positive:'Using custom filter', negative:'Use custom filter',value:'custom'}, {positive:'Using alarm filter', negative:'Use alarm filter', value:'id'}]"
                       [(value)]="alarmIdOrCustom"
                       (valueChange)="reloadFilter()"></micro-radio>
          <micro-alarmFilter-select *ngIf="alarmIdOrCustom === 'id'" [(alarmFilterId)]="subscription.alarmFilterId" (alarmFilterIdChange)="reloadFilter()" [required]="true"></micro-alarmFilter-select>
        </div>
        <div *ngIf="alarmIdOrCustom === 'custom'" class="padded dark-background">
          <micro-jpa-filter-editor [(search)]="subscription.customAlarmFilter"
                                   [height]="200"
                                   [autoApply]="true"
                                   (searchChange)="reloadFilter()"
                                   name="customAlarmFilter">
          </micro-jpa-filter-editor>
        </div>
        <micro-alarms *ngIf="alarmFilter" [embedded]="true" [useFilter]="true" [alarmFilter]="alarmFilter"></micro-alarms>
      </mat-tab>
      <mat-tab *ngIf="subscription.id" label="Escalations">
        <div class="vbox">
          <micro-escalations [subscriptionId]="subscription.id" [autoRefresh]="true"></micro-escalations>
        </div>
      </mat-tab>
      <mat-tab *ngIf="subscription.id" label="History">
        <micro-audits objectType="Subscription" [objectId]="subscription.id"></micro-audits>
      </mat-tab>
      <mat-tab *ngIf="subscription.id && (!subscription.protected || subscription.__permGRANT)" label="Security">
        <micro-perm-grid objectType="Subscription" [objectId]="subscription.id"></micro-perm-grid>
        <micro-permLogs objectType="Subscription" [objectId]="subscription.id"></micro-permLogs>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>

